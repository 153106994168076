import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withCookies, Cookies } from 'react-cookie';
import {
  Field,
  reduxForm,
  SubmissionError,
  propTypes as reduxFormPropTypes,
  stopSubmit,
} from 'redux-form';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { getTrialPageUrl } from '../../helpers';
import isLoggedIn from '../../helpers/checkAuth';

import history from '../../history';

import s from './Login.scss';
import modalStyles from '../styles/Modal.scss';
import textFieldStyles from '../styles/TextField.scss';
import validate from './loginValidation';

import * as authActions from '../../actions/auth';
import * as registrationActions from '../../actions/registration';

import ForgotPassword from '../ForgotPassword/ForgotPassword';
import messages from './loginMessages';
import Register from '../Register/Register';
import TooltipCloseButton from '../statelessComponents/Tooltip/TooltipButton';
import TextField from '../statelessComponents/FormFields/TextField';
// eslint-disable-next-line css-modules/no-unused-class
import dropdownStyles from '../styles/DropDown.scss';
import FacebookIcon from '../statelessComponents/Icons/FacebookIcon';
import GoogleIcon from '../statelessComponents/Icons/GoogleIcon';
import HakaIcon from '../statelessComponents/Icons/HakaIcon';
import SkholeIcon42 from '../statelessComponents/Icons/SkholeIcon42';

const IconComponent = () => <SkholeIcon42 className={s.loginSelectIcon} />;
@injectIntl
@withCookies
@withStyles(s)
@withStyles(textFieldStyles)
@withStyles(modalStyles)
@withStyles(dropdownStyles)
@reduxForm({
  form: 'loginForm',
  enableReinitialize: true,
  validate,
})
@connect(
  state => ({
    locale: state.intl.locale,
    auth: state.auth,
    lang: state.intl.locale,
  }),
  dispatch =>
    bindActionCreators({ ...authActions, ...registrationActions }, dispatch),
)
export default class Login extends React.Component {
  static propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    form: PropTypes.object, // eslint-disable-line
    loadAuth: PropTypes.func.isRequired,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    onClose: PropTypes.func.isRequired,
    openSignUp: PropTypes.func.isRequired,
    query: PropTypes.object, // eslint-disable-line
    intl: intlShape.isRequired,
    ...reduxFormPropTypes,
  };

  static defaultProps = {
    query: {},
  };

  static contextTypes = {
    apiUrl: PropTypes.string,
    cookiePrefix: PropTypes.string,
    fetch: PropTypes.func,
    whitelabeling: PropTypes.shape({}),
    organizationSlug: PropTypes.string,
    query: PropTypes.shape({ educatorRedirect: PropTypes.string }),
    educatorAiBaseUrl: PropTypes.string.isRequired,
  };

  state = {
    signUpModal: {
      isSignUpModalShown: false,
    },
    isForgotPasswordShown: false,
    snackbarMessage: '',
    fbAppId: '',
    googleAppId: '',
    loginMethod: '',
  };

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({
      fbAppId: window.App.fbAppId,
      googleAppId: window.App.googleAppId,
    });
    if (isLoggedIn(this.props.auth)) {
      this.handleSuccessLogin();
    }
    if (window.gapi) {
      window.gapi.load('auth2', () => {
        this.auth2 = window.gapi.auth2.init({
          client_id: window.App.googleAppId,
          scope: 'profile email',
          responseType: 'code',
          onFailure: console.error,
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isLoggedIn(this.props.auth) && isLoggedIn(nextProps.auth)) {
      history.replace(`/${this.props.locale}`); // todo
    }
  }

  handleSuccessLogin = () => {
    if (this.context.query?.educatorRedirect) {
      window.location = `${this.context.educatorAiBaseUrl}${this.context.query.educatorRedirect}`;
      return;
    }
    window.location = window.location.href.split('?')[0];
  };

  submit = data => {
    const { cookies, onClose, lang } = this.props;

    cookies.remove(`${this.context.cookiePrefix}token`, {
      path: '/',
      domain: window.App.cookieDomain,
    });

    return this.context
      .fetch('/api/auth/login', {
        method: 'POST',
        body: JSON.stringify(_.omit(data, ['loginMethod'])),
      })
      .catch(err => {
        throw new SubmissionError({ _error: err && err.message });
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.isArchivedTrial) {
              if (resJson.trialExpiredPageContent) {
                window.location = `/${lang}${
                  this.context.organizationSlug
                    ? `/${this.context.organizationSlug}`
                    : ''
                }/trial-expired/${resJson.mainGroupId}`;
                return Promise.reject();
              }
              window.location = getTrialPageUrl(lang);
              return Promise.reject();
            }
            if (resJson.message) {
              throw new SubmissionError({ _error: resJson.message });
            }
            throw new SubmissionError(resJson);
          }
          return resJson;
        }),
      )
      .then(token => {
        if (token.chooseMainGroupToken) {
          cookies.set(
            `${this.context.cookiePrefix}chooseMainGroupToken`,
            token.chooseMainGroupToken,
            {
              path: '/',
              maxAge: token.maxAge,
              domain: window.App.cookieDomain,
            },
          );
        } else if (token.isOtpRequired) {
          cookies.set(
            `${this.context.cookiePrefix}isLoginOtpRequired`,
            token.isOtpRequired,
            {
              path: '/',
              maxAge: token.maxAge,
              domain: window.App.cookieDomain,
            },
          );
        } else {
          cookies.set(`${this.context.cookiePrefix}token`, token.token, {
            path: '/',
            maxAge: token.maxAge,
            domain: window.App.cookieDomain,
          });
          this.handleSuccessLogin();
        }
      })
      .then(() => {
        onClose();
      });
  };

  toggleModal = value => {
    const { signUpModal } = this.state;
    signUpModal.isSignUpModalShown = value;

    this.setState({
      signUpModal,
    });
  };

  toggleForgotPassword = isForgotPasswordShown => {
    this.setState({
      isForgotPasswordShown,
    });
  };

  handleLoginMethodChange = (e, newValue) => {
    this.setState({
      loginMethod: newValue,
    });
  };

  handleLoginWithSelectedMethod = () => {
    const { loginMethod, fbAppId } = this.state;
    switch (loginMethod) {
      case 'facebook':
        if (fbAppId) document.querySelector('#facebook-login').click();
        break;
      case 'google':
        this.loginGoogle();
        break;
      case 'haka':
        window.location.href = '/api/saml';
        break;
      case 'suomisport':
        this.loginSuomisport();
        break;
      default:
        break;
    }
  };

  loginGoogle = () => {
    if (this.state.googleAppId) {
      this.auth2
        .grantOfflineAccess()
        .then(this.responseGoogle)
        .catch(console.error);
    }
  };

  loginSuomisport = () => {
    const { fetch } = this.context;
    fetch(`/api/auth/suomisport/login`)
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.message) {
              this.setState({ snackbarMessage: resJson.message });
              return Promise.reject();
            }
            this.setState({ snackbarMessage: resJson });
            return Promise.reject();
          }
          return resJson;
        }),
      )
      .then(resJson => {
        if (resJson && resJson.authUrl) {
          window.location.href = resJson.authUrl;
        }
      });
  };

  responseFacebook = fbResponse => {
    const {
      cookies,
      onClose,
      setRegistrationForm,
      openSignUp,
      dispatch,
      lang,
    } = this.props;
    const { fbAppId } = this.state;
    const redirectUri = window.location.origin;

    if (!fbResponse.accessToken) return;

    cookies.remove(`${this.context.cookiePrefix}token`, {
      path: '/',
      domain: window.App.cookieDomain,
    });

    this.context
      .fetch('/api/auth/facebook', {
        method: 'POST',
        body: JSON.stringify({
          redirectUri,
          clientId: fbAppId,
          accessToken: fbResponse.accessToken,
        }),
      })
      .catch(err => {
        dispatch(stopSubmit('loginForm', { _error: err && err.message }));
        return Promise.reject();
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.isArchivedTrial) {
              if (resJson.trialExpiredPageContent) {
                window.location = `/${lang}${
                  this.context.organizationSlug
                    ? `/${this.context.organizationSlug}`
                    : ''
                }/trial-expired/${resJson.mainGroupId}`;
                return Promise.reject();
              }
              window.location = getTrialPageUrl(lang);
              return Promise.reject();
            }

            if (resJson.message) {
              dispatch(stopSubmit('loginForm', { _error: resJson.message }));
              return Promise.reject();
            }
            dispatch(stopSubmit('loginForm', resJson));
            return Promise.reject();
          }
          return resJson;
        }),
      )
      .then(resJson => {
        if (resJson.isOtpRequired) {
          cookies.set(
            `${this.context.cookiePrefix}isLoginOtpRequired`,
            resJson.isOtpRequired,
            {
              path: '/',
              maxAge: resJson.maxAge,
              domain: window.App.cookieDomain,
            },
          );
          onClose();
          return;
        }
        if (resJson && resJson.chooseMainGroupToken) {
          cookies.set(
            `${this.context.cookiePrefix}chooseMainGroupToken`,
            resJson.chooseMainGroupToken,
            {
              path: '/',
              maxAge: resJson.maxAge,
              domain: window.App.cookieDomain,
            },
          );
          onClose();
          return;
        }
        if (resJson && resJson.token) {
          cookies.set(`${this.context.cookiePrefix}token`, resJson.token, {
            path: '/',
            domain: window.App.cookieDomain,
          });

          if (resJson.isTrial) {
            window.location = getTrialPageUrl(lang);
            return;
          }

          this.handleSuccessLogin();
          onClose();
          return;
        }

        if (resJson && resJson.facebookId) {
          setRegistrationForm(resJson);
          onClose();
          openSignUp();
        }
      });
  };

  responseGoogle = googleRes => {
    const {
      dispatch,
      cookies,
      onClose,
      setRegistrationForm,
      openSignUp,
      lang,
    } = this.props;
    const { googleAppId } = this.state;
    const redirectUri = window.location.origin;

    cookies.remove(`${this.context.cookiePrefix}token`, {
      path: '/',
      domain: window.App.cookieDomain,
    });

    return this.context
      .fetch('/api/auth/google', {
        method: 'POST',
        body: JSON.stringify({
          redirectUri,
          clientId: googleAppId,
          code: googleRes.code,
        }),
      })
      .catch(err => {
        dispatch(stopSubmit('loginForm', { _error: err && err.message }));
        return Promise.reject();
      })
      .then(res =>
        res.json().then(resJson => {
          if (!res.ok) {
            if (resJson.isArchivedTrial) {
              if (resJson.trialExpiredPageContent) {
                window.location = `/${lang}${
                  this.context.organizationSlug
                    ? `/${this.context.organizationSlug}`
                    : ''
                }/trial-expired/${resJson.mainGroupId}`;
                return Promise.reject();
              }
              window.location = getTrialPageUrl(lang);
              return Promise.reject();
            }

            if (resJson.message) {
              dispatch(stopSubmit('loginForm', { _error: resJson.message }));
              return Promise.reject();
            }
            dispatch(stopSubmit('loginForm', resJson));
            return Promise.reject();
          }
          return resJson;
        }),
      )
      .then(resJson => {
        if (resJson.isOtpRequired) {
          cookies.set(
            `${this.context.cookiePrefix}isLoginOtpRequired`,
            resJson.isOtpRequired,
            {
              path: '/',
              maxAge: resJson.maxAge,
              domain: window.App.cookieDomain,
            },
          );
          onClose();
          return;
        }
        if (resJson && resJson.chooseMainGroupToken) {
          cookies.set(
            `${this.context.cookiePrefix}chooseMainGroupToken`,
            resJson.chooseMainGroupToken,
            {
              path: '/',
              maxAge: resJson.maxAge,
              domain: window.App.cookieDomain,
            },
          );
          onClose();
          return;
        }

        if (resJson && resJson.token) {
          cookies.set(`${this.context.cookiePrefix}token`, resJson.token, {
            path: '/',
            domain: window.App.cookieDomain,
          });

          if (resJson.isTrial) {
            window.location = getTrialPageUrl(lang);
            return;
          }

          this.handleSuccessLogin();
          onClose();
          return;
        }

        if (resJson && resJson.googleId) {
          setRegistrationForm(resJson);
          onClose();
          openSignUp();
        }
      });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      form,
      auth,
      error,
      onClose,
      query,
      intl: { formatMessage },
    } = this.props;
    const {
      isForgotPasswordShown,
      fbAppId,
      signUpModal,
      loginMethod,
      snackbarMessage,
    } = this.state;
    const { whitelabeling } = this.context;

    if (isLoggedIn(auth)) {
      return null;
    }

    return (
      <div className={modalStyles.paper} id="login-modal">
        <Typography variant="h4" component="h1" className={s.lead}>
          <FormattedMessage {...messages.logInText} />
        </Typography>
        <form noValidate autoComplete="off">
          <Field
            name="email"
            type="text"
            margin="dense"
            component={TextField}
            placeholder={formatMessage(messages.emailLabel)}
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
            normalize={value => value && value.toLowerCase()}
          />
          <Field
            name="password"
            type="password"
            margin="dense"
            component={TextField}
            placeholder={formatMessage(messages.passwordLabel)}
            InputProps={{
              classes: {
                root: textFieldStyles.textField,
                input: textFieldStyles.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: textFieldStyles.textFieldError,
              },
            }}
            fullWidth
          />
          {error && (
            <Typography gutterBottom color="error">
              {error}
            </Typography>
          )}
          {_.get(query, 'error', '') && (
            <Typography gutterBottom color="error">
              {query.error}
            </Typography>
          )}
          <Button
            id="login-modal-btn"
            color="primary"
            type="submit"
            margin="dense"
            size="large"
            onClick={handleSubmit(this.submit)}
            disabled={!!(form && form.asyncErrors) || submitting}
            className={s.logInBtn}
            fullWidth
          >
            <FormattedMessage {...messages.submitButtonText} />
          </Button>
          <Button
            className={s.forgotPassword}
            onClick={() => this.toggleForgotPassword(true)}
            fullWidth
          >
            <FormattedMessage {...messages.forgotPasswordButtonText} />
          </Button>
          <Button
            className={s.forgotPassword}
            onClick={() => this.toggleModal(true)}
            fullWidth
          >
            <FormattedMessage {...messages.signUpButtonText} />
          </Button>
        </form>
        <strong className={s.lineThrough}>
          <FormattedMessage {...messages.orOptionText} />
        </strong>
        <div className={s.formGroup}>
          <Field
            name="loginMethod"
            type="text"
            fullWidth
            component={TextField}
            select
            onChange={this.handleLoginMethodChange}
            classes={{
              root: s.loginSelectRoot,
            }}
            SelectProps={{
              IconComponent,
              displayEmpty: true,
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: s.selectInput,
                input: s.textFieldInput,
                focused: textFieldStyles.textFieldFocused,
                disabled: textFieldStyles.textFieldDisabled,
                error: dropdownStyles.textFieldError,
              },
            }}
            margin="none"
          >
            <MenuItem
              value=""
              disabled
              classes={{
                root: s.menuItemRoot,
              }}
            >
              <FormattedMessage {...messages.chooseLoginMethod} />
            </MenuItem>
            <MenuItem
              classes={{
                root: s.menuItemRoot,
              }}
              value="facebook"
            >
              <FacebookIcon className={s.facebookIcon} />
              <FormattedMessage {...messages.continueWithFBText} />
            </MenuItem>
            <MenuItem
              classes={{
                root: s.menuItemRoot,
              }}
              value="google"
            >
              <GoogleIcon className={s.googleIcon} />
              <FormattedMessage {...messages.continueWithGoogleText} />
            </MenuItem>
            <MenuItem
              classes={{
                root: s.menuItemRoot,
              }}
              value="haka"
            >
              <HakaIcon className={s.hakaIcon} />
              <FormattedMessage {...messages.continueWithHakaText} />
            </MenuItem>
            <MenuItem
              classes={{
                root: s.menuItemRoot,
              }}
              value="suomisport"
            >
              <FormattedMessage {...messages.continueWithSuomisportText} />
            </MenuItem>
          </Field>
        </div>
        <div className={s.formGroup}>
          <Button
            className={s.button}
            disabled={!loginMethod}
            onClick={this.handleLoginWithSelectedMethod}
          >
            <FormattedMessage {...messages.loginWithSelectedMethod} />
          </Button>
        </div>
        {fbAppId && (
          <div className={s.hiddenBtn}>
            <FacebookLogin
              id="login-modal-facebook-btn"
              appId={fbAppId}
              autoLoad={false}
              fields="id,name,email,first_name,last_name"
              callback={this.responseFacebook}
              responseType="code"
              disableMobileRedirect
              render={props => (
                <Button {...props} className={s.button} id="facebook-login">
                  <span className={s.facebookIcon} />
                  <span>
                    <FormattedMessage {...messages.continueWithFBText} />
                  </span>
                </Button>
              )}
            />
          </div>
        )}
        <Button
          id="login-modal-help-btn"
          className={s.needHelp}
          component="a"
          href="https://support.skhole.fi"
          target="_blank"
          fullWidth
        >
          <FormattedMessage {...messages.needHelp} />
        </Button>
        <TooltipCloseButton message="closeButton">
          <div className={modalStyles.closeModalBtn}>
            <IconButton onClick={onClose}>
              <CloseIcon className={modalStyles.closeIcon} />
            </IconButton>
          </div>
        </TooltipCloseButton>

        <Snackbar
          open={!!snackbarMessage}
          onClose={() => {
            this.setState({ snackbarMessage: '' });
          }}
          autoHideDuration={3000}
          message={snackbarMessage}
        />

        <Modal
          aria-labelledby="forgot-password-modal"
          aria-describedby="forgot-password-modal"
          open={isForgotPasswordShown}
          onClose={() => this.toggleForgotPassword(false)}
          disableBackdropClick
        >
          <ForgotPassword onClose={() => this.toggleForgotPassword(false)} />
        </Modal>
        <Modal
          aria-labelledby="register-modal"
          aria-describedby="register-modal"
          open={!!signUpModal.isSignUpModalShown}
          onClose={() => this.toggleModal(false)}
          disableBackdropClick
        >
          <Register
            onClose={() => this.toggleModal(false)}
            query={query}
            fetch={this.context.fetch}
            membershipIdVerificationEnabled={_.get(
              whitelabeling,
              'membershipIdVerificationEnabled',
            )}
          />
        </Modal>
      </div>
    );
  }
}
