export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
// export const SET_IS_NEW_VERSION_AVAILABLE = 'SET_IS_NEW_VERSION_AVAILABLE';

export const SAVE_AUTH = 'SAVE_AUTH';
export const SAVE_FAVORITE_COURSES = 'SAVE_FAVORITE_COURSES';
export const SAVE_FOLLOWED_DISCUSSIONS = 'SAVE_FOLLOWED_DISCUSSIONS';

export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const LOGOUT_AUTH_SUCCESS = 'LOGOUT_AUTH_SUCCESS';
export const LOGOUT_AUTH_FAIL = 'LOGOUT_AUTH_FAIL';

export const EDIT_COURSE__GET_COURSE = 'EDIT_COURSE__GET_COURSE';
export const EDIT_COURSE__GET_COURSE_SUCCESS =
  'EDIT_COURSE__GET_COURSE_SUCCESS';
export const EDIT_COURSE__GET_COURSE_FAIL = 'EDIT_COURSE_GET__COURSE_FAIL';

// export const EDIT_COURSE__GET_COURSE_RELATIONS =
//   'EDIT_COURSE__GET_COURSE_RELATIONS';
// export const EDIT_COURSE__GET_COURSE_RELATIONS_SUCCESS =
//   'EDIT_COURSE__GET_COURSE_RELATIONS_SUCCESS';
// export const EDIT_COURSE__GET_COURSE_RELATIONS_FAIL =
//   'EDIT_COURSE__GET_COURSE_RELATIONS_FAIL';

export const EDIT_COURSE__GET_OPENED_EDITOR_COURSE =
  'EDIT_COURSE__GET_OPENED_EDITOR_COURSE';
export const EDIT_COURSE__GET_OPENED_EDITOR_COURSE_SUCCESS =
  'EDIT_COURSE__GET_OPENED_EDITOR_COURSE_SUCCESS';
export const EDIT_COURSE__GET_OPENED_EDITOR_COURSE_FAIL =
  'EDIT_COURSE__GET_OPENED_EDITOR_COURSE_FAIL';
export const EDIT_COURSE__SET_OPENED_EDITOR_COURSE =
  'EDIT_COURSE__SET_OPENED_EDITOR_COURSE';

export const EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL =
  'EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL';
export const EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_SUCCESS =
  'EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_SUCCESS';
export const EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_FAIL =
  'EDIT_COURSE_TAKE_COURSE_EDIT_CONTROL_FAIL';

export const EDIT_COURSE__RESET_COURSE = 'EDIT_COURSE__RESET_COURSE';

export const GET_LESSONS = 'GET_LESSONS';
export const GET_LESSONS_SUCCESS = 'GET_LESSONS_SUCCESS';
export const GET_LESSONS_FAIL = 'GET_LESSONS_FAIL';
export const RESET_LESSONS = 'RESET_LESSONS';

export const HOME_GET_LESSONS = 'HOME_GET_LESSONS';
export const HOME_GET_LESSONS_SUCCESS = 'HOME_GET_LESSONS_SUCCESS';
export const HOME_GET_LESSONS_FAIL = 'HOME_GET_LESSONS_FAIL';
export const HOME_RESET_LESSONS = 'HOME_RESET_LESSONS';

export const GET_LESSON = 'GET_LESSON';
export const GET_LESSON_SUCCESS = 'GET_LESSON_SUCCESS';
export const GET_LESSON_FAIL = 'GET_LESSON_FAIL';
export const UPDATE_LESSON_UPLOADING_ATTACHMENT =
  'UPDATE_LESSON_UPLOADING_ATTACHMENT';

export const EDIT_LESSON__SET_OPENED_EDITOR = 'EDIT_LESSON__SET_OPENED_EDITOR';

export const EDIT_LESSON__GET_OPENED_EDITOR = 'EDIT_LESSON__GET_OPENED_EDITOR';
export const EDIT_LESSON__GET_OPENED_EDITOR_SUCCESS =
  'EDIT_LESSON__GET_OPENED_EDITOR_SUCCESS';
export const EDIT_LESSON__GET_OPENED_EDITOR_FAIL =
  'EDIT_LESSON__GET_OPENED_EDITOR_FAIL';

export const EDIT_LESSON_TAKE_EDIT_CONTROL = 'EDIT_LESSON_TAKE_EDIT_CONTROL';
export const EDIT_LESSON_TAKE_EDIT_CONTROL_SUCCESS =
  'EDIT_LESSON_TAKE_EDIT_CONTROL_SUCCESS';
export const EDIT_LESSON_TAKE_EDIT_CONTROL_FAIL =
  'EDIT_LESSON_TAKE_EDIT_CONTROL_FAIL';

export const RESET_LESSON = 'RESET_LESSON';

export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';
export const RESET_COURSES = 'RESET_COURSES';

export const COURSE_DETAILS__GET_COURSE = 'COURSE_DETAILS__GET_COURSE';
export const COURSE_DETAILS__GET_COURSE_SUCCESS =
  'COURSE_DETAILS__GET_COURSE_SUCCESS';
export const COURSE_DETAILS__GET_COURSE_FAIL =
  'COURSE_DETAILS__GET_COURSE_FAIL';
export const COURSE_DETAILS__RESET_COURSE = 'COURSE_DETAILS__RESET_COURSE';

export const COURSE_DETAILS__RESET_COURSE_PROGRESS =
  'COURSE_DETAILS__RESET_COURSE_PROGRESS';

export const COURSE_DETAILS__GET_RELATED_COURSES =
  'COURSE_DETAILS__GET_RELATED_COURSES';
export const COURSE_DETAILS__GET_RELATED_COURSES_SUCCESS =
  'COURSE_DETAILS__GET_RELATED_COURSES_SUCCESS';
export const COURSE_DETAILS__GET_RELATED_COURSES_FAIL =
  'COURSE_DETAILS__GET_RELATED_COURSES_FAIL';

export const COURSE_DETAILS__GET_TEXT_NOTES = 'COURSE_DETAILS__GET_TEXT_NOTES';
export const COURSE_DETAILS__GET_TEXT_NOTES_SUCCESS =
  'COURSE_DETAILS__GET_TEXT_NOTES_SUCCESS';
export const COURSE_DETAILS__GET_TEXT_NOTES_FAIL =
  'COURSE_DETAILS__GET_TEXT_NOTES_FAIL';

export const COURSE_DETAILS__GET_VIDEO_NOTES =
  'COURSE_DETAILS__GET_VIDEO_NOTES';
export const COURSE_DETAILS__GET_VIDEO_NOTES_SUCCESS =
  'COURSE_DETAILS__GET_VIDEO_NOTES_SUCCESS';
export const COURSE_DETAILS__GET_VIDEO_NOTES_FAIL =
  'COURSE_DETAILS__GET_VIDEO_NOTES_FAIL';

export const COURSE_DETAILS__GET_PUBLIC_NOTES_STATE =
  'COURSE_DETAILS__GET_PUBLIC_NOTES_STATE';
export const COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_SUCCESS =
  'COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_SUCCESS';
export const COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_FAIL =
  'COURSE_DETAILS__GET_PUBLIC_NOTES_STATE_FAIL';
export const COURSE_DETAILS__TOGGLE_LIVESTREAM_CHAT =
  'COURSE_DETAILS__TOGGLE_LIVESTREAM_CHAT';

export const LESSON_DETAILS__GET_LESSON = 'LESSON_DETAILS__GET_LESSON';
export const LESSON_DETAILS__GET_LESSON_SUCCESS =
  'LESSON_DETAILS__GET_LESSON_SUCCESS';
export const LESSON_DETAILS__GET_LESSON_FAIL =
  'LESSON_DETAILS__GET_LESSON_FAIL';
export const LESSON_DETAILS__GET_LESSON_TEXT_NOTES =
  'LESSON_DETAILS__GET_LESSON_TEXT_NOTES';
export const LESSON_DETAILS__GET_LESSON_TEXT_NOTES_SUCCESS =
  'LESSON_DETAILS__GET_LESSON_TEXT_NOTES_SUCCESS';
export const LESSON_DETAILS__GET_LESSON_TEXT_NOTES_FAIL =
  'LESSON_DETAILS__GET_LESSON_TEXT_NOTES_FAIL';
export const LESSON_DETAILS__GET_LESSON_VIDEO_NOTES =
  'LESSON_DETAILS__GET_LESSON_VIDEO_NOTES';
export const LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_SUCCESS =
  'LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_SUCCESS';
export const LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_FAIL =
  'LESSON_DETAILS__GET_LESSON_VIDEO_NOTES_FAIL';
export const LESSON_DETAILS__RESET_LESSON = 'LESSON_DETAILS__RESET_LESSON';
export const LESSON_DETAILS__UPDATE_STATE = 'LESSON_DETAILS__UPDATE_STATE';
export const LESSON_DETAILS__COMPLETE_LESSON =
  'LESSON_DETAILS__COMPLETE_LESSON';
export const LESSON_DETAILS__COMPLETE_LESSON_SUCCESS =
  'LESSON_DETAILS__COMPLETE_LESSON_SUCCESS';
export const LESSON_DETAILS__COMPLETE_LESSON_FAIL =
  'LESSON_DETAILS__COMPLETE_LESSON_FAIL';
export const LESSON_DETAILS__COMPLETE_LESSON_RESET_ERROR =
  'LESSON_DETAILS__COMPLETE_LESSON_RESET_ERROR';
export const LESSON_DETAILS__RESET_VIDEO_NOTES =
  'LESSON_DETAILS__RESET_VIDEO_NOTES';
export const LESSON_DETAILS__RESET_TEXT_NOTES =
  'LESSON_DETAILS__RESET_TEXT_NOTES';
export const LESSON_DETAILS__GET_LESSON_CHANGELOG =
  'LESSON_DETAILS__GET_LESSON_CHANGELOG';
export const LESSON_DETAILS__GET_LESSON_CHANGELOG_SUCCESS =
  'LESSON_DETAILS__GET_LESSON_CHANGELOG_SUCCESS';
export const LESSON_DETAILS__GET_LESSON_CHANGELOG_FAIL =
  'LESSON_DETAILS__GET_LESSON_CHANGELOG_FAIL';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const RESET_USERS = 'RESET_USERS';

export const GET_MAIN_GROUPS = 'GET_MAIN_GROUPS';
export const GET_MAIN_GROUPS_SUCCESS = 'GET_MAIN_GROUPS_SUCCESS';
export const GET_MAIN_GROUPS_FAIL = 'GET_MAIN_GROUPS_FAIL';
export const GET_ALL_MAIN_GROUPS = 'GET_ALL_MAIN_GROUPS';
export const GET_ALL_MAIN_GROUPS_SUCCESS = 'GET_ALL_MAIN_GROUPS_SUCCESS';
export const GET_ALL_MAIN_GROUPS_FAIL = 'GET_ALL_MAIN_GROUPS_FAIL';
export const RESET_MAIN_GROUPS = 'RESET_MAIN_GROUPS';
export const SET_DEFAULT_SUB_ORGANIZATION_TYPE =
  'SET_DEFAULT_SUB_ORGANIZATION_TYPE';

export const GET_SUBGROUPS = 'GET_SUBGROUPS';
export const GET_SUBGROUPS_SUCCESS = 'GET_SUBGROUPS_SUCCESS';
export const GET_SUBGROUPS_FAIL = 'GET_SUBGROUPS_FAIL';
export const GET_ALL_SUBGROUPS = 'GET_ALL_SUBGROUPS';
export const GET_ALL_SUBGROUPS_SUCCESS = 'GET_ALL_SUBGROUPS_SUCCESS';
export const GET_ALL_SUBGROUPS_FAIL = 'GET_ALL_SUBGROUPS_FAIL';
export const RESET_SUBGROUPS = 'RESET_SUBGROUPS';

export const GET_MAIN_GROUP = 'GET_MAIN_GROUP';
export const GET_MAIN_GROUP_SUCCESS = 'GET_MAIN_GROUP_SUCCESS';
export const GET_MAIN_GROUP_FAIL = 'GET_MAIN_GROUP_FAIL';
export const RESET_MAIN_GROUP = 'RESET_MAIN_GROUP';

export const GET_SUBGROUP = 'GET_SUBGROUP';
export const GET_SUBGROUP_SUCCESS = 'GET_SUBGROUP_SUCCESS';
export const GET_SUBGROUP_FAIL = 'GET_SUBGROUP_FAIL';
export const SET_SUBGROUP = 'SET_SUBGROUP';
export const RESET_SUBGROUP = 'RESET_SUBGROUP';
export const GET_SUBGROUP_TEACHERS = 'GET_SUBGROUP_TEACHERS';
export const GET_SUBGROUP_TEACHERS_SUCCESS = 'GET_SUBGROUP_TEACHERS_SUCCESS';
export const GET_SUBGROUP_TEACHERS_FAIL = 'GET_SUBGROUP_TEACHERS_FAIL';

export const GET_QUIZZES = 'GET_QUIZZES';
export const GET_QUIZZES_SUCCESS = 'GET_QUIZZES_SUCCESS';
export const GET_QUIZZES_FAIL = 'GET_QUIZZES_FAIL';
export const RESET_QUIZZES = 'RESET_QUIZZES';

export const EDIT_QUIZ__GET_QUIZ = 'EDIT_QUIZ__GET_QUIZ';
export const EDIT_QUIZ__GET_QUIZ_SUCCESS = 'EDIT_QUIZ__GET_QUIZ_SUCCESS';
export const EDIT_QUIZ__GET_QUIZ_FAIL = 'EDIT_QUIZ__GET_QUIZ_FAIL';
export const SET_QUIZ = 'SET_QUIZ';
export const SET_QUIZ_ASSOCIATED_COURSE = 'SET_QUIZ_ASSOCIATED_COURSE';
export const RESET_QUIZ = 'RESET_QUIZ';

export const EDIT_QUIZ__SET_OPENED_EDITOR = 'EDIT_LESSON__SET_OPENED_EDITOR';

export const EDIT_QUIZ__GET_OPENED_EDITOR = 'EDIT_QUIZ__GET_OPENED_EDITOR';
export const EDIT_QUIZ__GET_OPENED_EDITOR_SUCCESS =
  'EDIT_QUIZ__GET_OPENED_EDITOR_SUCCESS';
export const EDIT_QUIZ__GET_OPENED_EDITOR_FAIL =
  'EDIT_QUIZ__GET_OPENED_EDITOR_FAIL';

export const EDIT_QUIZ_TAKE_EDIT_CONTROL = 'EDIT_QUIZ_TAKE_EDIT_CONTROL';
export const EDIT_QUIZ_TAKE_EDIT_CONTROL_SUCCESS =
  'EDIT_QUIZ_TAKE_EDIT_CONTROL_SUCCESS';
export const EDIT_QUIZ_TAKE_EDIT_CONTROL_FAIL =
  'EDIT_QUIZ_TAKE_EDIT_CONTROL_FAIL';

export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
export const RESET_TAGS = 'RESET_TAGS';

export const GET_CONTENT_CATEGORIES = 'GET_CONTENT_CATEGORIES';
export const GET_CONTENT_CATEGORIES_SUCCESS = 'GET_CONTENT_CATEGORIES_SUCCESS';
export const GET_CONTENT_CATEGORIES_FAIL = 'GET_CONTENT_CATEGORIES_FAIL';
export const RESET_CONTENT_CATEGORIES = 'RESET_CONTENT_CATEGORIES';

export const MANAGE_GET_TAGS = 'MANAGE_GET_TAGS';
export const MANAGE_GET_TAGS_SUCCESS = 'MANAGE_GET_TAGS_SUCCESS';
export const MANAGE_GET_TAGS_FAIL = 'MANAGE_GET_TAGS_FAIL';
export const MANAGE_GET_ALL_TAGS = 'MANAGE_GET_ALL_TAGS';
export const MANAGE_GET_ALL_TAGS_SUCCESS = 'MANAGE_GET_ALL_TAGS_SUCCESS';
export const MANAGE_GET_ALL_TAGS_FAIL = 'MANAGE_GET_ALL_TAGS_FAIL';
export const MANAGE_RESET_TAGS = 'MANAGE_RESET_TAGS';

export const GET_TAG = 'GET_TAG';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAIL = 'GET_TAG_FAIL';
export const RESET_TAG = 'RESET_TAG';

export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA';
export const RESET_REGISTRATION_DATA = 'RESET_REGISTRATION_DATA';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const GET_USER_COMPLETED_COURSES = 'GET_USER_COMPLETED_COURSES';
export const GET_USER_COMPLETED_COURSES_SUCCESS =
  'GET_USER_COMPLETED_COURSES_SUCCESS';
export const GET_USER_COMPLETED_COURSES_FAIL =
  'GET_USER_COMPLETED_COURSES_FAIL';

export const GET_USER_PUBLIC_NOTES = 'GET_USER_PUBLIC_NOTES';
export const GET_USER_PUBLIC_NOTES_SUCCESS = 'GET_USER_PUBLIC_NOTES_SUCCESS';
export const GET_USER_PUBLIC_NOTES_FAIL = 'GET_USER_PUBLIC_NOTES_FAIL';

export const GET_USER_PUBLIC_CERTIFICATES = 'GET_USER_PUBLIC_CERTIFICATES';
export const GET_USER_PUBLIC_CERTIFICATES_SUCCESS =
  'GET_USER_PUBLIC_CERTIFICATES_SUCCESS';
export const GET_USER_PUBLIC_CERTIFICATES_FAIL =
  'GET_USER_PUBLIC_CERTIFICATES_FAIL';

export const RESET_USER = 'RESET_USER';

export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_AUTHORS_SUCCESS = 'GET_AUTHORS_SUCCESS';
export const GET_AUTHORS_FAIL = 'GET_AUTHORS_FAIL';
export const GET_ALL_AUTHORS = 'GET_ALL_AUTHORS';
export const GET_ALL_AUTHORS_SUCCESS = 'GET_ALL_AUTHORS_SUCCESS';
export const GET_ALL_AUTHORS_FAIL = 'GET_ALL_AUTHORS_FAIL';
export const RESET_AUTHORS = 'RESET_AUTHORS';

export const GET_AUTHOR = 'GET_AUTHOR';
export const GET_AUTHOR_SUCCESS = 'GET_AUTHOR_SUCCESS';
export const GET_AUTHOR_FAIL = 'GET_AUTHOR_FAIL';
export const RESET_AUTHOR = 'RESET_AUTHOR';
export const SET_AUTHOR = 'SET_AUTHOR';

export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUESTIONS_FAIL';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';

export const EDIT_QUESTION__GET_QUESTION = 'EDIT_QUESTION__GET_QUESTION';
export const EDIT_QUESTION__GET_QUESTION_SUCCESS =
  'EDIT_QUESTION__GET_QUESTION_SUCCESS';
export const EDIT_QUESTION__GET_QUESTION_FAIL =
  'EDIT_QUESTION__GET_QUESTION_FAIL';
export const SET_QUESTION = 'SET_QUESTION';
export const SET_QUESTION_ASSOCIATED_COURSE = 'SET_QUESTION_ASSOCIATED_COURSE';
export const RESET_QUESTION = 'RESET_QUESTION';

export const EDIT_QUESTION__SET_OPENED_EDITOR =
  'EDIT_QUESTION__SET_OPENED_EDITOR';

export const EDIT_QUESTION__GET_OPENED_EDITOR =
  'EDIT_QUESTION__GET_OPENED_EDITOR';
export const EDIT_QUESTION__GET_OPENED_EDITOR_SUCCESS =
  'EDIT_QUESTION__GET_OPENED_EDITOR_SUCCESS';
export const EDIT_QUESTION__GET_OPENED_EDITOR_FAIL =
  'EDIT_QUESTION__GET_OPENED_EDITOR_FAIL';

export const EDIT_QUESTION_TAKE_EDIT_CONTROL =
  'EDIT_QUESTION_TAKE_EDIT_CONTROL';
export const EDIT_QUESTION_TAKE_EDIT_CONTROL_SUCCESS =
  'EDIT_QUESTION_TAKE_EDIT_CONTROL_SUCCESS';
export const EDIT_QUESTION_TAKE_EDIT_CONTROL_FAIL =
  'EDIT_QUESTION_TAKE_EDIT_CONTROL_FAIL';

export const CHANGE_SEARCH = 'CHANGE_SEARCH';
export const CHANGE_SEARCH_SESSION = 'CHANGE_SEARCH_SESSION';
export const CHANGE_TAGS = 'CHANGE_TAGS';
export const CHANGE_PENDING_TAGS = 'CHANGE_PENDING_TAGS';
export const CHANGE_CONTENT_CATEGORIES = 'CHANGE_CONTENT_CATEGORIES';
export const CHANGE_PENDING_CONTENT_CATEGORIES =
  'CHANGE_PENDING_CONTENT_CATEGORIES';
export const CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD =
  'CHANGE_IS_INITIAL_CONTENT_CATEGORIES_LOAD';

export const VIEW_QUIZ__GET_QUIZ = 'VIEW_QUIZ__GET_QUIZ';
export const VIEW_QUIZ__GET_QUIZ_SUCCESS = 'VIEW_QUIZ__GET_QUIZ_SUCCESS';
export const VIEW_QUIZ__GET_QUIZ_FAIL = 'VIEW_QUIZ__GET_QUIZ_FAIL';

export const START_QUIZ__GET_QUIZ = 'START_QUIZ__GET_QUIZ';
export const START_QUIZ__GET_QUIZ_SUCCESS = 'START_QUIZ__GET_QUIZ_SUCCESS';
export const START_QUIZ__GET_QUIZ_FAIL = 'START_QUIZ__GET_QUIZ_FAIL';

export const QUIZ_RESULT__GET_RESULT = 'QUIZ_RESULT__GET_RESULT';
export const QUIZ_RESULT__GET_RESULT_SUCCESS =
  'QUIZ_RESULT__GET_RESULT_SUCCESS';
export const QUIZ_RESULT__GET_RESULT_FAIL = 'QUIZ_RESULT__GET_RESULT_FAIL';
export const RESET_RESULT = 'RESET_RESULT';

export const GET_MY_CERTIFICATES = 'GET_MY_CERTIFICATES';
export const GET_MY_CERTIFICATES_SUCCESS = 'GET_MY_CERTIFICATES_SUCCESS';
export const GET_MY_CERTIFICATES_FAIL = 'GET_MY_CERTIFICATES_FAIL';
export const RESET_MY_CERTIFICATES = 'RESET_MY_CERTIFICATES';

export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS';
export const GET_CERTIFICATE_FAIL = 'GET_CERTIFICATE_FAIL';
export const RESET_CERTIFICATE = 'RESET_CERTIFICATE';
export const SET_CERTIFICATE = 'SET_CERTIFICATE';

export const GET_DISCUSSIONS_REQUEST = 'GET_DISCUSSIONS_REQUEST';
export const GET_DISCUSSIONS_SUCCESS = 'GET_DISCUSSIONS_SUCCESS';
export const GET_DISCUSSIONS_FAIL = 'GET_DISCUSSIONS_FAIL';
export const RESET_DISCUSSIONS = 'RESET_DISCUSSIONS';

export const GET_DISCUSSION = 'GET_DISCUSSION';
export const GET_DISCUSSION_SUCCESS = 'GET_DISCUSSION_SUCCESS';
export const GET_DISCUSSION_FAIL = 'GET_DISCUSSION_FAIL';
export const RESET_DISCUSSION = 'RESET_DISCUSSION';

export const GET_DISCUSSION_MESSAGES = 'GET_DISCUSSION_MESSAGES';
export const GET_DISCUSSION_MESSAGES_SUCCESS =
  'GET_DISCUSSION_MESSAGES_SUCCESS';
export const GET_DISCUSSION_MESSAGES_FAIL = 'GET_DISCUSSION_MESSAGES_FAIL';
export const RESET_DISCUSSION_MESSAGES = 'RESET_DISCUSSION_MESSAGES';

export const GET_GROUP_TASKS = 'GET_GROUP_TASKS';
export const GET_GROUP_TASKS_SUCCESS = 'GET_GROUP_TASKS_SUCCESS';
export const GET_GROUP_TASKS_FAIL = 'GET_GROUP_TASKS_FAIL';
export const RESET_GROUP_TASKS = 'RESET_GROUP_TASKS';

export const EDIT_TASK__GET_TASK = 'EDIT_TASK__GET_TASK';
export const EDIT_TASK__GET_TASK_SUCCESS = 'EDIT_TASK__GET_TASK_SUCCESS';
export const EDIT_TASK__GET_TASK_FAIL = 'EDIT_TASK__GET_TASK_FAIL';
export const RESET_TASK = 'RESET_TASK';
export const SET_TASK = 'SET_TASK';
export const SET_TASK_START_LOADING = 'SET_TASK_START_LOADING';
export const SET_TASK_STOP_LOADING = 'SET_TASK_STOP_LOADING';

export const GET_MY_TASKS = 'GET_MY_TASKS';
export const GET_MY_TASKS_SUCCESS = 'GET_MY_TASKS_SUCCESS';
export const GET_MY_TASKS_FAIL = 'GET_MY_TASKS_FAIL';
export const RESET_MY_TASKS = 'RESET_MY_TASKS';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const APPEND_NOTIFICATION = 'APPEND_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const RESET_PAGINATION_NOTIFICATION = 'RESET_PAGINATION_NOTIFICATION';

export const GET_TASK_RESULTS = 'GET_TASK_RESULTS';
export const GET_TASK_RESULTS_SUCCESS = 'GET_TASK_RESULTS_SUCCESS';
export const GET_TASK_RESULTS_FAIL = 'GET_TASK_RESULTS_FAIL';
export const RESET_TASK_RESULTS = 'RESET_TASK_RESULTS';

export const GET_ATTEMPTS_RESULTS = 'GET_ATTEMPTS_RESULTS';
export const GET_ATTEMPTS_RESULTS_SUCCESS = 'GET_ATTEMPTS_RESULTS_SUCCESS';
export const GET_ATTEMPTS_RESULTS_FAIL = 'GET_ATTEMPTS_RESULTS_FAIL';
export const RESET_ATTEMPTS_RESULTS = 'RESET_ATTEMPTS_RESULTS';

export const GET_QUIZ_ATTEMPTS_RESULTS = 'GET_QUIZ_ATTEMPTS_RESULTS';
export const GET_QUIZ_ATTEMPTS_RESULTS_SUCCESS =
  'GET_QUIZ_ATTEMPTS_RESULTS_SUCCESS';
export const GET_QUIZ_ATTEMPTS_RESULTS_FAIL = 'GET_QUIZ_ATTEMPTS_RESULTS_FAIL';
export const RESET_QUIZ_ATTEMPTS_RESULTS = 'RESET_QUIZ_ATTEMPTS_RESULTS';

export const GET_QUIZ_STATISTICS = 'GET_QUIZ_STATISTICS';
export const GET_QUIZ_STATISTICS_SUCCESS = 'GET_QUIZ_STATISTICS_SUCCESS';
export const GET_QUIZ_STATISTICS_FAIL = 'GET_QUIZ_STATISTICS_FAIL';
export const RESET_QUIZ_STATISTICS = 'RESET_QUIZ_STATISTICS';

export const GET_ASSIGNMENT_STATISTICS = 'GET_ASSIGNMENT_STATISTICS';
export const GET_ASSIGNMENT_STATISTICS_SUCCESS =
  'GET_ASSIGNMENT_STATISTICS_SUCCESS';
export const GET_ASSIGNMENT_STATISTICS_FAIL = 'GET_ASSIGNMENT_STATISTICS_FAIL';
export const RESET_ASSIGNMENT_STATISTICS = 'RESET_ASSIGNMENT_STATISTICS';

export const GET_MY_COURSES = 'GET_MY_COURSES';
export const GET_MY_COURSES_SUCCESS = 'GET_MY_COURSES_SUCCESS';
export const GET_MY_COURSES_FAIL = 'GET_MY_COURSES_FAIL';
export const RESET_MY_COURSES = 'RESET_MY_COURSES';

export const GET_STATISTIC_INFO_REQUEST = 'GET_STATISTIC_INFO_REQUEST';
export const GET_STATISTIC_INFO_SUCCESS = 'GET_STATISTIC_INFO_SUCCESS';
export const GET_STATISTIC_INFO_FAIL = 'GET_STATISTIC_INFO_FAIL';

export const GET_COURSE_STATISTICS = 'GET_COURSE_STATISTICS';
export const GET_COURSE_STATISTICS_SUCCESS = 'GET_COURSE_STATISTICS_SUCCESS';
export const GET_COURSE_STATISTICS_FAIL = 'GET_COURSE_STATISTICS_FAIL';
export const RESET_COURSE_STATISTICS = 'RESET_COURSE_STATISTICS';

export const GET_STUDIED_LESSONS = 'GET_STUDIED_LESSONS';
export const GET_STUDIED_LESSONS_SUCCESS = 'GET_STUDIED_LESSONS_SUCCESS';
export const GET_STUDIED_LESSONS_FAIL = 'GET_STUDIED_LESSONS_FAIL';
export const RESET_STUDIED_LESSONS = 'RESET_STUDIED_LESSONS';

export const GET_QUIZ_IMPROVERS = 'GET_QUIZ_IMPROVERS';
export const GET_QUIZ_IMPROVERS_SUCCESS = 'GET_QUIZ_IMPROVERS_SUCCESS';
export const GET_QUIZ_IMPROVERS_FAIL = 'GET_QUIZ_IMPROVERS_FAIL';
export const RESET_QUIZ_IMPROVERS = 'RESET_QUIZ_IMPROVERS';

export const QUIZ_RESULT__GET_RESULT_ATTEMPTS =
  'QUIZ_RESULT__GET_RESULT_ATTEMPTS';
export const QUIZ_RESULT__GET_RESULT_ATTEMPTS_SUCCESS =
  'QUIZ_RESULT__GET_RESULT_ATTEMPTS_SUCCESS';
export const QUIZ_RESULT__GET_RESULT_ATTEMPTS_FAIL =
  'QUIZ_RESULT__GET_RESULT_ATTEMPTS_FAIL';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA';

export const GET_DASHBOARD_DISCUSSIONS_REQUEST =
  'GET_DASHBOARD_DISCUSSIONS_REQUEST';
export const GET_DASHBOARD_DISCUSSIONS_SUCCESS =
  'GET_DASHBOARD_DISCUSSIONS_SUCCESS';
export const GET_DASHBOARD_DISCUSSIONS_FAIL = 'GET_DASHBOARD_DISCUSSIONS_FAIL';
export const RESET_DASHBOARD_DISCUSSIONS = 'RESET_DASHBOARD_DISCUSSIONS';

export const TOGGLE_NOTIFICATIONS_POPOVER = 'TOGGLE_NOTIFICATIONS_POPOVER';

export const GET_TRANSCODING_JOBS = 'GET_TRANSCODING_JOBS';
export const GET_TRANSCODING_JOBS_SUCCESS = 'GET_TRANSCODING_JOBS_SUCCESS';
export const GET_TRANSCODING_JOBS_FAIL = 'GET_TRANSCODING_JOBS_FAIL';
export const RESET_TRANSCODING_JOBS = 'RESET_TRANSCODING_JOBS';
export const UPDATE_TRANSCODING_JOB = 'UPDATE_TRANSCODING_JOB';

export const GET_USERS_ACTIVITY_STATISTICS = 'GET_USERS_ACTIVITY_STATISTICS';
export const GET_USERS_ACTIVITY_STATISTICS_SUCCESS =
  'GET_USERS_ACTIVITY_STATISTICS_SUCCESS';
export const GET_USERS_ACTIVITY_STATISTICS_FAIL =
  'GET_USERS_ACTIVITY_STATISTICS_FAIL';
export const RESET_GET_USERS_ACTIVITY_STATISTICS =
  'RESET_GET_USERS_ACTIVITY_STATISTICS';

export const TOGGLE_TOUR = 'TOGGLE_TOUR';
// export const CHANGE_ON_LINE = 'CHANGE_ON_LINE';
export const CHANGE_PWA_OFF_ON_LINE = 'CHANGE_PWA_OFF_ON_LINE';
// export const UPDATE_SW_STATE = 'UPDATE_SW_STATE';

export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAIL = 'GET_DEALS_FAIL';
export const RESET_DEALS = 'RESET_DEALS';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';

// export const TOGGLE_IS_COURSE_DOWNLOADING = 'TOGGLE_IS_COURSE_DOWNLOADING';

export const GET_XLSX_EXPORT = 'GET_XLSX_EXPORT';
export const GET_XLSX_EXPORT_SUCCESS = 'GET_XLSX_EXPORT_SUCCESS';
export const GET_XLSX_EXPORT_FAIL = 'GET_XLSX_EXPORT_FAIL';
export const RESET_XLSX_EXPORT = 'RESET_XLSX_EXPORT';

export const ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT =
  'ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT';
export const ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS =
  'ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS';
export const ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL =
  'ASSIGNMENT_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL';
export const ASSIGNMENT_TASK__GET_TASK_HISTORY =
  'ASSIGNMENT_TASK__GET_TASK_HISTORY';
export const ASSIGNMENT_TASK__GET_TASK_HISTORY_SUCCESS =
  'ASSIGNMENT_TASK__GET_TASK_HISTORY_SUCCESS';
export const ASSIGNMENT_TASK__GET_TASK_HISTORY_FAIL =
  'ASSIGNMENT_TASK__GET_TASK_HISTORY_FAIL';
export const ASSIGNMENT_TASK__GET_USER_TASK_RESULT =
  'ASSIGNMENT_TASK__GET_USER_TASK_RESULT';
export const ASSIGNMENT_TASK__GET_USER_TASK_RESULT_SUCCESS =
  'ASSIGNMENT_TASK__GET_USER_TASK_RESULT_SUCCESS';
export const ASSIGNMENT_TASK__GET_USER_TASK_RESULT_FAIL =
  'ASSIGNMENT_TASK__GET_USER_TASK_RESULT_FAIL';
export const RESET_ASSIGNMENT_TASK = 'RESET_ASSIGNMENT_TASK';

export const PODCAST_LESSON_SET_EXPANDED_LESSON =
  'PODCAST_LESSON_SET_EXPANDED_LESSON';
export const PODCAST_LESSON_SET_PLAY_PODCAST_AUDIO =
  'PODCAST_LESSON_SET_PLAY_PODCAST_AUDIO';
export const PODCAST_LESSON_SET_NEXT_LESSON = 'PODCAST_LESSON_SET_NEXT_LESSON';
export const PODCAST_LESSON_RESET = 'PODCAST_LESSON_RESET';
export const PODCAST_LESSON_SET_IS_LOADING = 'PODCAST_LESSON_SET_IS_LOADING';

export const GET_COURSE_REVIEW = 'GET_COURSE_REVIEW';
export const GET_COURSE_REVIEW_SUCCESS = 'GET_COURSE_REVIEW_SUCCESS';
export const GET_COURSE_REVIEW_FAIL = 'GET_COURSE_REVIEW_FAIL';

export const GET_USER_COURSE_COMPLETED_LESSONS =
  'GET_USER_COURSE_COMPLETED_LESSONS';
export const GET_USER_COURSE_COMPLETED_LESSONS_SUCCESS =
  'GET_USER_COURSE_COMPLETED_LESSONS_SUCCESS';
export const GET__USER_COURSE_COMPLETED_LESSONS_FAIL =
  'GET__USER_COURSE_COMPLETED_LESSONS_FAIL';
export const RESET__USER_COURSE_COMPLETED_LESSONS =
  'RESET__USER_COURSE_COMPLETED_LESSONS';

export const GET_MAIN_GROUP_TRIAL_INFO = 'GET_MAIN_GROUP_TRIAL_INFO';
export const GET_MAIN_GROUP_TRIAL_INFO_SUCCESS =
  'GET_MAIN_GROUP_TRIAL_INFO_SUCCESS';
export const GET_MAIN_GROUP_TRIAL_INFO_FAIL = 'GET_MAIN_GROUP_TRIAL_INFO_FAIL';
export const RESET_MAIN_GROUP_TRIAL_INFO = 'RESET_MAIN_GROUP_TRIAL_INFO';

export const GET_API_KEYS = 'GET_API_KEYS';
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';
export const GET_API_KEYS_FAIL = 'GET_API_KEYS_FAIL';
export const RESET_API_KEYS = 'RESET_API_KEYS';

export const GET_API_KEY = 'GET_API_KEY';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAIL = 'GET_API_KEY_FAIL';
export const SET_API_KEY = 'SET_API_KEY';
export const RESET_API_KEY = 'RESET_API_KEY';

export const GENERATE_OTP = 'GENERATE_OTP';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAIL = 'GENERATE_OTP_FAIL';
export const RESET_OTP = 'RESET_OTP';

export const GET_DOCUMENT_FORMS = 'GET_DOCUMENT_FORMS';
export const GET_DOCUMENT_FORMS_SUCCESS = 'GET_DOCUMENT_FORMS_SUCCESS';
export const GET_DOCUMENT_FORMS_FAIL = 'GET_DOCUMENT_FORMS_FAIL';
export const RESET_DOCUMENT_FORMS = 'RESET_DOCUMENT_FORMS';

export const GET_DOCUMENT_FORM = 'GET_DOCUMENT_FORM';
export const GET_DOCUMENT_FORM_SUCCESS = 'GET_DOCUMENT_FORM_SUCCESS';
export const GET_DOCUMENT_FORM_FAIL = 'GET_DOCUMENT_FORM_FAIL';
export const SET_DOCUMENT_FORM = 'SET_DOCUMENT_FORM';
export const RESET_DOCUMENT_FORM = 'RESET_DOCUMENT_FORM';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAIL = 'GET_DOCUMENT_FAIL';
export const RESET_DOCUMENT = 'RESET_DOCUMENT';
export const SET_DOCUMENT = 'SET_DOCUMENT';

export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS';
export const GET_USER_DOCUMENTS_SUCCESS = 'GET_USER_DOCUMENTS_SUCCESS';
export const GET_USER_DOCUMENTS_FAIL = 'GET_USER_DOCUMENTS_FAIL';

export const GET_USER_DOCUMENT = 'GET_USER_DOCUMENT';
export const GET_USER_DOCUMENT_SUCCESS = 'GET_USER_DOCUMENT_SUCCESS';
export const GET_USER_DOCUMENT_FAIL = 'GET_USER_DOCUMENT_FAIL';
export const RESET_USER_DOCUMENT = 'RESET_USER_DOCUMENT';
export const SET_USER_DOCUMENT = 'SET_USER_DOCUMENT';

export const GET_DEMONSTRATIONS = 'GET_DEMONSTRATIONS';
export const GET_DEMONSTRATIONS_SUCCESS = 'GET_DEMONSTRATIONS_SUCCESS';
export const GET_DEMONSTRATIONS_FAIL = 'GET_DEMONSTRATIONS_FAIL';
export const RESET_DEMONSTRATIONS = 'RESET_DEMONSTRATIONS';

export const GET_DEMONSTRATION = 'GET_DEMONSTRATION';
export const GET_DEMONSTRATION_SUCCESS = 'GET_DEMONSTRATION_SUCCESS';
export const GET_DEMONSTRATION_FAIL = 'GET_DEMONSTRATION_FAIL';
export const RESET_DEMONSTRATION = 'RESET_DEMONSTRATION';
export const SET_DEMONSTRATION = 'SET_DEMONSTRATION';

export const DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT =
  'DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT';
export const DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS =
  'DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_SUCCESS';
export const DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL =
  'DEMONSTRATION_TASK__GET_CURRENT_USER_TASK_RESULT_FAIL';
export const DEMONSTRATION_TASK__GET_TASK_HISTORY =
  'DEMONSTRATION_TASK__GET_TASK_HISTORY';
export const DEMONSTRATION_TASK__GET_TASK_HISTORY_SUCCESS =
  'DEMONSTRATION_TASK__GET_TASK_HISTORY_SUCCESS';
export const DEMONSTRATION_TASK__GET_TASK_HISTORY_FAIL =
  'DEMONSTRATION_TASK__GET_TASK_HISTORY_FAIL';
export const DEMONSTRATION_TASK__GET_USER_TASK_RESULT =
  'DEMONSTRATION_TASK__GET_USER_TASK_RESULT';
export const DEMONSTRATION_TASK__GET_USER_TASK_RESULT_SUCCESS =
  'DEMONSTRATION_TASK__GET_USER_TASK_RESULT_SUCCESS';
export const DEMONSTRATION_TASK__GET_USER_TASK_RESULT_FAIL =
  'DEMONSTRATION_TASK__GET_USER_TASK_RESULT_FAIL';
export const RESET_DEMONSTRATION_TASK = 'RESET_DEMONSTRATION_TASK';

export const GET_DEMONSTRATION_STATISTICS = 'GET_DEMONSTRATION_STATISTICS';
export const GET_DEMONSTRATION_STATISTICS_SUCCESS =
  'GET_DEMONSTRATION_STATISTICS_SUCCESS';
export const GET_DEMONSTRATION_STATISTICS_FAIL =
  'GET_DEMONSTRATION_STATISTICS_FAIL';
export const RESET_DEMONSTRATION_STATISTICS = 'RESET_DEMONSTRATION_STATISTICS';

export const userRole = {
  GROUP_MANAGER: 'groupmanager',
  GROUP_LEADER: 'groupleader',
  TEACHER: 'teacher',
  ADMIN: 'admin',
  STUDENT: 'student',
  EDITOR: 'editor',
};

export const INTERCOM_DEFAULT_HORIZONTAL_PADDING = 20;
export const INTERCOM_DEFAULT_VERTICAL_PADDING = 20;

export const COURSE_TYPES_FILTER_VALUES = {
  MY_COURSES: 'my_courses',
  SKHOLE: 'skhole',
  CUSTOMERS_COURSES: 'customers_courses',
};

export const DATA_PICKER_VIEWS_VALUES = {
  DATE: 'date',
  YEAR: 'year',
  HOURS: 'hours',
  MINUTES: 'minutes',
};

export const DEVELOPMENT_ENV = 'development';
export const PRODUCTION_ENV = 'production';
